import '@assets/homepage.scss';

function toggleMenu(menu: Element) {
  menu.classList.toggle('mobile-nav-toggle--active');
}

function generateMailto() {
  const mail = 'untempspourunautre@gmail.com';
  const subject = 'Prise de contact par site web';
  const contactMail = document.getElementById('email') as HTMLInputElement;
  const tel = document.getElementById('tel')  as HTMLInputElement;
  const needs = document.getElementById('needs') as HTMLInputElement;
  const url = 'mailto:'+ mail
  + '?subject=' + subject
  + '&body=' + 'Votre téléphone : ' + tel?.value
  + '\nVotre mail : ' + contactMail?.value
  + '\nVotre besoin : ' + needs?.value;

  window.location.href = encodeURI(url);
}

const menu = document.querySelector('#mobile-nav');
const formMailto = document.querySelector('#mailto');

menu?.addEventListener('click', () => toggleMenu(menu));
formMailto?.addEventListener('click', () => generateMailto());
